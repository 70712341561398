import React from "react"
import {
  AboutSection,
  ArticlesSection,
  ContactSection,
  HeroSection,
  InterestsSection,
  Page,
  ProjectsSection,
  Seo,
} from "gatsby-theme-portfolio-minimal"
import CustomHeroSection from "../components/CustomHeroSection"

export default function IndexPage() {
  return (
    <>
      <Seo title="Anselm Christophersen" />
      <Page useSplashScreenAnimation={false}>
        <CustomHeroSection />
        {/* <HeroSection sectionId="hero" /> */}
        {/* <ArticlesSection section  Id="articles" heading="Latest Articles" sources={['Medium']} /> */}
        <AboutSection sectionId="about" heading="About Me" />
        {/* <InterestsSection sectionId="details" heading="Details" /> */}
        {/* <ProjectsSection sectionId="features" heading="Built-in Features" /> */}
        <ContactSection sectionId="contact" heading="Contact" />
      </Page>
    </>
  )
}
