import React from "react"
import { Animation } from "gatsby-theme-portfolio-minimal/src/components/Animation"
import { Section } from "gatsby-theme-portfolio-minimal/src/components/Section"

export default function CustomHeroSection() {
  return (
    <Animation type="fadeUp" delay={400}>
      <Section anchor={"hero"} style={{ marginTop: "-30px" }}>
        <h3 style={{ marginBottom: "0px" }}>
          Hi there, I'm{" "}
          <span style={{ color: "rgb(131, 131, 131" }}>Anselm</span>,
        </h3>
        <h1>
          I am the{" "}
          <span style={{ color: "rgb(131, 131, 131" }}>Chapter Lead</span> on
          the{" "}
          <span style={{ color: "rgb(131, 131, 131" }}>Front-End Chapter</span>{" "}
          <br />
          in the{" "}
          <span style={{ color: "rgb(131, 131, 131" }}>
            Financing Business Tribe
          </span>{" "}
          in <span style={{ color: "rgb(131, 131, 131" }}>Danske Bank</span>
          <br />
          in{" "}
          <span style={{ color: "rgb(131, 131, 131" }}>
            Copenhagen, Denmark
          </span>
          .
        </h1>
        <h3 style={{}}>
          I'm leading a team of 10 skilled Front-End developers,
          <br /> specialized on the{" "}
          <span style={{ color: "rgb(131, 131, 131" }}>React/Node.js</span>{" "}
          stack.
        </h3>
      </Section>
    </Animation>
  )
}
